/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { Buffer } from "buffer";
import React, { Fragment, useEffect, useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Lazy,
} from "swiper";
import "swiper/css/bundle";
import {
  configureChains,
  createConfig,
  WagmiConfig,
  useAccount,
  useWalletClient,
  useSwitchNetwork,
} from "wagmi";
import "@rainbow-me/rainbowkit/styles.css";
import {
  ConnectButton,
  RainbowKitProvider,
  darkTheme,
  useConnectModal,
} from "@rainbow-me/rainbowkit";
import { coinbaseWallet, metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import { Col, Row } from "reactstrap";
import { polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { sequenceWallet } from "@0xsequence/rainbowkit-plugin";

import diamond from "../../assets/images/daily/Diamond.svg";
import discord from "../../assets/images/discord.svg";
import teligram from "../../assets/images/teligram.svg";
import twitter from "../../assets/images/twitter.svg";
import medium from "../../assets/images/medium.svg";
import hocPlayNowBanner from "../../assets/daily/playNow.png";

import { useCountdown } from "../../hooks/useCountdown";
import { requestToken, getWithdrawTime } from "../../chain/claim";
import { isMobile } from "react-device-detect";
const projectId = "b172966957f43e118c43c1e0a82f6370";
const polygonMainnetChainId = 137;

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Lazy]);

const publicRpcUrl = "https://rpc.ankr.com/polygon";

const { chains, publicClient } = configureChains(
  [polygon],
  [
    jsonRpcProvider({
      rpc: () => ({ http: publicRpcUrl }),
    }),
    publicProvider(),
  ]
);
const connectors = connectorsForWallets([
  {
    groupName: "Available",
    wallets: [
      coinbaseWallet({
        chains,
        shimDisconnect: true,
        projectId,
        defaultNetwork: "polygon",
      }),
      metaMaskWallet({
        chains,
        shimDisconnect: true,
        projectId,
        defaultNetwork: "polygon",
      }),
      // sequenceWallet({
      //   chains,
      //   defaultNetwork: "polygon",
      //   shimDisconnect: true,
      //   connect: {
      //     app: "HuntersOnChain",
      //   },
      // }),
    ],
  },
]);

const wagmiConfig = createConfig({
  connectors,
  autoConnect: true,
  publicClient,
});

function DailyReward() {
  const { openConnectModal } = useConnectModal();
  const { connector, isConnected, address } = useAccount();
  const { data: signer } = useWalletClient();
  const [isClaimable, setIsClaimable] = useState(false);
  const [nextClaimTime, setNextClaimTime] = useState();
  const [playNowBanner, setPlayNowBanner] = useState("https://hoc.boomland.io");
  const { switchNetworkAsync } = useSwitchNetwork();

  // for Spinner

  const [isSubmitting, setIsSubmitting] = useState(false);
  const spinnerCss = css`
    display: block;
    margin: 0 auto;
  `;

  useEffect(() => {
    async function updateTime() {
      const claimTime = await timeHandler();
      setNextClaimTime(claimTime);
    }

    if (!isConnected) return;

    updateTime();
  }, [isConnected, connector]);

  // Custom Components

  const Counter = ({ hours, minutes, seconds }) => {
    return (
      <div className="d-flex flex-row countdown-container justify-content-center">
        <span className="d-flex flex-column">
          <span className="countdown-time-section">
            {hours.toString().padStart(2, "0")}
          </span>
          <span className="countdown-label">HOURS</span>
        </span>
        <span className="countdown-time-section"> : </span>
        <span className="d-flex flex-column">
          <span className="countdown-time-section">
            {minutes.toString().padStart(2, "0")}
          </span>
          <span className="countdown-label">MINUTES</span>
        </span>
        <span className="countdown-time-section"> : </span>
        <span className="d-flex flex-column">
          <span className="countdown-time-section">
            {seconds.toString().padStart(2, "0")}
          </span>
          <span className="countdown-label">SECONDS</span>
        </span>
      </div>
    );
  };

  const ClaimCountdownTimer = ({ targetDate, onCountdownFinished }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    useEffect(() => {
      if (days + hours + minutes + seconds <= 0) {
        onCountdownFinished();
      }
    }, [days, hours, minutes, seconds, onCountdownFinished]);

    if (days + hours + minutes + seconds > 0) {
      return (
        <Counter
          hours={days * 24 + hours}
          minutes={minutes}
          seconds={seconds}
        />
      );
    }
  };

  // Helper Functions & CTAs

  const claimHandler = async () => {
    try {
      if (connector?.id === "sequence")
        await switchNetworkAsync(polygonMainnetChainId);
      setIsSubmitting(true);

      const claimResult = await requestToken(signer, connector);

      if (claimResult.success) {
        setNextClaimTime(await timeHandler());
        setIsClaimable(false);
      }
      setIsSubmitting(false);
    } catch (e) {
      console.error(e);
      setIsSubmitting(false);
    }
  };

  const timeHandler = async () => {
    try {
      const timeResult = await getWithdrawTime(address);
      if (timeResult.time == 0) return Date.now();
      else return parseInt(timeResult.time) * 1000;
    } catch (e) {
      console.error(e);
    }
  };

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  useEffect(() => {
    if (isMobile) {
      const operatingSystem = getMobileOperatingSystem();

      operatingSystem === "iOS"
        ? setPlayNowBanner("https://testflight.apple.com/join/mnB642dv")
        : setPlayNowBanner(
            "https://play.google.com/store/apps/details?id=com.hunters.on.chain.mainnet"
          );
    } else {
      setPlayNowBanner("https://hoc.boomland.io");
    }
  }, [isMobile]);

  return (
    <Fragment>
      <div className="container-x">
        <a href={playNowBanner}>
          <img
            src={hocPlayNowBanner}
            alt="Play Now Banner"
            className="w-full py-4"
          />
        </a>

        {/* Title Section */}
        <div className="sortbar">
          <h3 style={{ fontSize: isMobile ? 18 : 24 }}>
            Claim your <b>free BGEM</b>
          </h3>
        </div>
        <span className="border-b" />
        {/* Reward Information Section */}
        <div className="mt-30 ">
          <Row className="justify-content-between gap-5">
            <Col className="bg-[#2d1c35] shadow-lg rounded-2xl pt-4 md:h-[480px] py-2">
              <div className="px-3">
                <h3 className="font-poppins font-extrabold md:text-6xl text-4xl">
                  Daily{" "}
                </h3>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={diamond}
                    alt="BGEM Image"
                    className="md:w-[180px] w-[120px] mt-4"
                  />
                </div>
                <div className="d-flex justify-content-end flex-column align-items-end mt-4">
                  <p className="font-poppins font-extrabold md:text-7xl text-5xl mt-1">
                    {" "}
                    5000
                  </p>
                  <p className="font-poppins font-extrabold md:text-5xl text-3xl">
                    {" "}
                    $BGEM
                  </p>
                </div>
              </div>
            </Col>
            {/* Claim Reward Section */}
            <Col className="bg-[#2d1c35] shadow-lg rounded-2xl  md:h-[480px] py-2 flex flex-col">
              <div className="px-3 pt-4 flex flex-col justify-center h-full w-full">
                {!isConnected ? (
                  <>
                    {/* Connect Wallet */}
                    <div className="flex flex-col justify-between items-center h-full flex-wrap">
                      <div className="flex flex-col justify-center mt-6">
                        <p className="font-poppins md:text-4xl text-xl self-center">
                          Claim your
                        </p>
                        <p className="font-poppins text-4xl">
                          <b>FREE</b> BGEM now
                        </p>
                      </div>

                      <button
                        id="btn-connect-wallet-qa"
                        className="w-100 btn-secondary-colapse btn-connect-wallet mb-4"
                        type="button"
                        onClick={openConnectModal}
                      >
                        Connect Wallet
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col justify-center items-center h-full">
                    <ConnectButton className="font-helper" />

                    <div className="md:text-4xl text-2xl h-full self-center py-4 flex flex-col justify-center">
                      <p className="md:text-4xl text-2xl font-poppins self-center">
                        {isClaimable
                          ? "Your claim is"
                          : "Next claim will be available in"}
                      </p>
                      <div className="mt-2">
                        {!isClaimable && (
                          <ClaimCountdownTimer
                            targetDate={nextClaimTime}
                            onCountdownFinished={() => setIsClaimable(true)}
                          />
                        )}
                        {isClaimable && (
                          <p className="md:text-6xl text-4xl font-poppins font-extrabold text-center mt-2">
                            Ready
                          </p>
                        )}
                      </div>
                    </div>

                    <button
                      id="btn-claim-reward-qa"
                      className={
                        isClaimable
                          ? "w-100 btn-secondary-colapse btn-connect-wallet mb-4"
                          : "w-100 btn-secondary-disabled btn-connect-wallet mb-4"
                      }
                      type="button"
                      disabled={!isClaimable || isSubmitting}
                      onClick={claimHandler}
                    >
                      {isSubmitting ? (
                        <ClipLoader css={spinnerCss} size={20} color={"#fff"} />
                      ) : (
                        "Claim"
                      )}
                    </button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="flex flex-col justify-center mt-12">
        <div className="flex flex-row mt-8 w-full items-center justify-center">
          <a
            href="https://discord.com/invite/boomland"
            target="_blank"
            rel="noreferrer"
          >
            <img src={discord} alt="Socials Discord" className="w-12 px-1" />
          </a>
          <a
            href="https://t.me/BoomlandAnnouncements"
            target="_blank"
            rel="noreferrer"
          >
            <img src={teligram} alt="Socials X" className="w-12 px-1" />
          </a>
          <a
            href="https://twitter.com/BoomLandGames"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="Socials Medium" className="w-12 px-1" />
          </a>
          <a
            href="https://boomlandgames.medium.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={medium} alt="Socials" className="w-12 px-1" />
          </a>
        </div>
        <div className="self-center py-1">
          © {new Date().getFullYear()} The BoomBit Group. All rights reserved.
        </div>
      </div>
    </Fragment>
  );
}

const DailyRewardComponent = () => (
  <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider chains={chains} theme={darkTheme()} modalSize="compact">
      <DailyReward />
    </RainbowKitProvider>
  </WagmiConfig>
);
export default DailyRewardComponent;
