import { SequenceIndexerClient } from "@0xsequence/indexer";
import {CONTRACT_ADDRESSES, CHAIN_ID} from "./chainConfig"

export async function getChestItems(account) {
    const indexerUrl =
        CHAIN_ID === 137
            ? "https://polygon-indexer.sequence.app"
            : "https://mumbai-indexer.sequence.app";

    const indexer = new SequenceIndexerClient(indexerUrl);

    // We could handle pagination of the indexer but page size is large enough that we don't care
    const indexerResponse = await indexer.getTokenBalances({
        contractAddress: CONTRACT_ADDRESSES[CHAIN_ID].MAGIC_EDEN,
        accountAddress: account,
    }).then((response) => response.balances);

    const tier2IndexerResponse = await indexer.getTokenBalances({
        contractAddress: CONTRACT_ADDRESSES[CHAIN_ID].MAGIC_EDEN_TIER2,
        accountAddress: account,
    }).then((response) => response.balances);

    return {
        tier1_length: indexerResponse.length,
        tier1_items: indexerResponse.map(balance => parseInt(balance.tokenID)),
        tier2_length: tier2IndexerResponse.length,
        tier2_items: tier2IndexerResponse.map(balance => parseInt(balance.tokenID))
    }
}
