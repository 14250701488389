export const shortenAddress = (address) => {
  if (address == null) return "N/A";

  return (
    address.substring(0, 6) +
    "..." +
    address.substring(address.length - 5, address.length)
  );
};

export const getUpgradedProperty = (data, level) => {
  let prop = parseInt(data);
  for (let i = 0; i < Math.floor(level); i++) {
    prop *= 1.1;
  }

  const upgradedProp = Math.ceil(prop);

  return upgradedProp;
};
