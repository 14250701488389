import {
  GET_CHEST_HUNTER,
  GET_CHEST_HUNTER_FAIL,
  GET_CHEST_COUNT,
  GET_CHEST_COUNT_FAIL,
  RESET_CHEST_COUNT,
  DECREASE_CHEST_COUNT,
} from "./actionTypes";

import { ChestHunter } from "../../services/store/store.service";
import { getChestItems } from "../../chain/chestItems";

import * as url from "../../helpers/url.helper";

export const getChestHunter = (data) => async (dispatch) => {
  return await ChestHunter(url.GET_CHEST_HUNTER, data)
    .then((response) => {
      // console.log(response?.data?.response);
      dispatch({
        type: GET_CHEST_HUNTER,
        payload: response?.data?.response?.hunterDetails,
      });
    })
    .catch((error) => {
      // console.log(error);
      dispatch({
        type: GET_CHEST_HUNTER_FAIL,
        payload: error,
      });
    });
};

export const getChestCount = (address) => async (dispatch) => {
  return await getChestItems(address)
    .then((response) => {
      //console.log(response)
      dispatch({
        type: GET_CHEST_COUNT,
        payload: { tier1: response?.tier1_length, tier2: response?.tier2_length }
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: GET_CHEST_COUNT_FAIL,
        payload: error,
      });
    });
};

export const resetChestCount = () => (dispatch) => {
  dispatch({
    type: RESET_CHEST_COUNT,
  });
};

export const decreaseChestCount = (chestType) => (dispatch) => {
  dispatch({
    type: DECREASE_CHEST_COUNT,
    payload: {chestType}
  });
};
